<template>
  <div>
    <van-form>
      <van-cell-group :title="types[type].title"></van-cell-group>
      <van-field
        key="mobile"
        v-show="type === 'mobile'"
        :readonly="codeSended"
        label="手机号码"
        placeholder="可接收短信的手机号码"
        ref="mobile"
        name="mobile"
        v-model="mobile"
        autocomplete="off"
      ></van-field>

      <van-field
        key="email"
        v-show="type === 'email'"
        :readonly="codeSended"
        label="邮箱号码"
        placeholder="格式如:330173689@qq.com"
        ref="email"
        name="email"
        v-model="email"
        autocomplete="off"
      ></van-field>
      <van-field
        label="验证码"
        :placeholder="`请输入${types[type].text}验证码`"
        ref="code"
        name="code"
        type="number"
        autocomplete="off"
        v-model="code"
      >
        <template #button>
          <van-button
            :disabled="hideCodeBtn"
            @click="sendCode"
            size="small"
            type="primary"
            >{{ sendCodeBtnText }}</van-button
          >
        </template>
      </van-field>
      <van-field
        label="设置密码"
        placeholder="6-20个英文或数字_"
        type="password"
        v-model="password"
        autocomplete="off"
      ></van-field>
      <van-field
        label="重复密码"
        type="password"
        autocomplete="off"
        placeholder="确认一遍密码"
        v-model="repassword"
      ></van-field>
      <van-cell>
        <van-button
          @click="setPassword"
          block
          icon="success"
          round
          type="primary"
          >注 册</van-button
        >
      </van-cell>
    </van-form>

    <van-divider
      :style="{
        color: '#1989fa',
        borderColor: '#1989fa',
        padding: '0 16px',
        marginTop: '60px',
      }"
      >其他注册方式</van-divider
    >
    <div class="tc mt-20">
      <van-button v-show="type !== 'mobile'" @click="changeType('mobile')"
        >使用手机号注册</van-button
      >
      <van-button v-show="type !== 'email'" @click="changeType('email')"
        >使用邮箱注册</van-button
      >
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { setToken, isPhoneAvailable } from "@/libs/tools";
import { Form, Field, Divider } from "vant";

Vue.use(Form).use(Field).use(Divider);

export default {
  name: "Register",
  data() {
    return {
      type: "mobile",
      limit: 60,
      code: "", // 填写的短信/邮箱验证码
      hideCodeBtn: false,
      codeSended: false, // 邮箱或短信验证码已发送成功
      mobile: "", // 手机号码
      email: "", // 邮箱号
      password: "",
      repassword: "",
      types: {
        mobile: {
          text: "短信",
          title: "使用手机号进行注册",
          codeUrl: "send_sms_code",
        },
        email: {
          text: "邮件",
          title: "使用邮箱进行注册",
          codeUrl: "send_email_code",
        },
      },
    };
  },
  computed: {
    sendCodeBtnText() {
      return this.hideCodeBtn ? "发送验证码 " + this.limit : "发送验证码";
    },
  },
  methods: {
    setPassword() {
      if (!this.mobile || !isPhoneAvailable(this.mobile)) {
        this.$dialog.alert({ message: "请输入有效手机号码" });
        return false;
      }
      const { mobile, email, type, code, password, repassword } = this;
      const param = { mobile, email, type, code, password, repassword };

      this.$http.post("do_register", param).then((res) => {
        this.$dialog.alert({ title: "提示", message: res.msg }).then(() => {
          if (res.code === 1) {
            setToken(res.data.token);
            this.$store.dispatch("getInit");
            const toPath = this.fromPath;
            if (toPath) {
              this.$router.replace({ path: toPath });
            } else {
              this.$router.replace({ name: "user" });
            }
          }
        });
      });
    },
    sendCode() {
      if (!this.mobile || !isPhoneAvailable(this.mobile)) {
        this.$dialog.alert({ message: "请输入有效手机号码" });
        return false;
      }

      this.getToken().then((res1) => {
        const param = {
          action: 1,
          [this.type]: this[this.type],
          __token__: res1.token,
        };
        const url = this.types[this.type].codeUrl;
        this.$http.post(url, param).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.codeSended = true;
            this.initTimer(60);
          }
          this.$dialog.alert({ title: "提示", message: msg }).then(() => {
            this.$refs.code.focus();
          });
        });
      });
    },
    getToken() {
      return this.$http.get("token");
    },
    initTimer(limit) {
      this.limit = limit;
      this.hideCodeBtn = true;
      this.timer = setInterval(() => {
        this.limit--;
        sessionStorage.setItem("regTimer", this.limit); // 写入缓存,防止刷新页面后倒计时重置
        if (this.limit <= 0) {
          this.limit = 0;
          this.hideCodeBtn = false;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    changeType(type) {
      this.$toast(this.types[type].title);
      this.type = type;
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
  created() {
    clearInterval(this.timer);
    const limit = sessionStorage.getItem("regTimer");
    if (limit > 0) {
      this.initTimer(+limit);
    }
  },
};
</script>
