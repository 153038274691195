<template>
  <div class="login-box">
    <div class="logo mb-20">
      <van-image :src="logo" width="70%" />
    </div>
    <van-tabs
      title-active-color="#ee0a24"
      v-model="tabActive"
      @change="tabChanged"
    >
      <van-tab name="login" title="登陆">
        <AccountLogin />
      </van-tab>
      <van-tab name="reg" title="注册">
        <AccountReg />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import AccountLogin from "./accountLogin.vue";
import AccountReg from "./accountReg";
import logo from "@/assets/logo.png";
import Vue from "vue";
import { Image, Tab, Tabs } from "vant";
Vue.use(Image).use(Tabs).use(Tab);

export default {
  name: "login",
  components: { AccountReg, AccountLogin },
  data() {
    return {
      logo: logo,
      list: [],
      tabActive: "login",
      wxLoginUrl: null,
    };
  },
  watch: {
    isLogin(isLogin) {
      if (isLogin) {
        this.$toast({ message: "您已登陆" });
        this.$router.replace({ name: "user" });
      }
    },
  },
  computed: {
    isLogin() {
      return this.$store.state.user?.user_id || false;
    },
  },
  methods: {
    getWxOauth() {
      this.$http.get("e/action/?act=wxoauth").then((res) => {
        if (res.code === 0) {
          this.wxLoginUrl = res.data;
        }
      });
    },
    tabChanged(tabName) {
      const titles = { login: "用户登陆", reg: "用户注册" };
      this.$store.commit("setPageTitle", titles[tabName]);
    },
  },
};
</script>

<style scoped>
.login-box {
  width: 100%;
  min-height: 100%;
}
.login-icon {
  font-size: 36px;
  margin-left: 10px;
}
.logo {
  text-align: center;
  padding: 10px 50px;
  background-color: #fff;
  margin-bottom: 1px;
}
</style>
