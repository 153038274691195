<template>
  <div>
    <van-cell-group>
      <van-field
        label="账号"
        placeholder="用户名/手机号/已绑定邮箱"
        required
        v-model="username"
      />
      <van-field
        label="密码"
        placeholder="请输入密码"
        required
        type="password"
        v-model="password"
      />
      <van-cell>
        <van-button
          :disabled="!loginBtnShow"
          @click="doLogin"
          round
          size="large"
          type="primary"
          >登录</van-button
        >
      </van-cell>
      <van-cell title="">
        <router-link :to="{ name: 'getPassword' }">找回密码</router-link>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import { setToken } from "@/libs/tools";
import Vue from "vue";
import { CellGroup, Field, Button } from "vant";

Vue.use(CellGroup).use(Field).use(Button);

export default {
  name: "accountLogin",
  data() {
    return {
      isloading: false,
      username: "",
      password: "",
    };
  },
  computed: {
    loginBtnShow() {
      return this.username.length >= 2 && this.password.length >= 6;
    },
    fromPath: function () {
      return this.$store.state.beforeLoginPath;
    },
  },
  methods: {
    doLogin() {
      var param = {
        username: this.username,
        pass: this.password,
      };
      this.$http.post("dologin", param).then((res) => {
        if (res.code === 1) {
          setToken(res.data.token);
          this.$store.dispatch("getInit");
          const toPath = this.fromPath;
          if (toPath) {
            this.$router.replace({ path: toPath });
          } else {
            this.$router.replace({ name: "user" });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.logo {
  text-align: center;
  padding: 30px 50px;
  background-color: #fff;
  margin-bottom: 1px;
}
</style>
